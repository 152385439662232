// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-all-tags-jsx": () => import("./../../../src/templates/allTags.jsx" /* webpackChunkName: "component---src-templates-all-tags-jsx" */),
  "component---src-templates-artworks-experimental-jsx": () => import("./../../../src/templates/artworks-experimental.jsx" /* webpackChunkName: "component---src-templates-artworks-experimental-jsx" */),
  "component---src-templates-artworks-index-jsx": () => import("./../../../src/templates/artworks-index.jsx" /* webpackChunkName: "component---src-templates-artworks-index-jsx" */),
  "component---src-templates-artworks-intro-jsx": () => import("./../../../src/templates/artworks-intro.jsx" /* webpackChunkName: "component---src-templates-artworks-intro-jsx" */),
  "component---src-templates-artworks-jsx": () => import("./../../../src/templates/artworks.jsx" /* webpackChunkName: "component---src-templates-artworks-jsx" */),
  "component---src-templates-artworks-portfolio-jsx": () => import("./../../../src/templates/artworks-portfolio.jsx" /* webpackChunkName: "component---src-templates-artworks-portfolio-jsx" */),
  "component---src-templates-artworks-simple-jsx": () => import("./../../../src/templates/artworks-simple.jsx" /* webpackChunkName: "component---src-templates-artworks-simple-jsx" */),
  "component---src-templates-augmented-reality-jsx": () => import("./../../../src/templates/augmented-reality.jsx" /* webpackChunkName: "component---src-templates-augmented-reality-jsx" */),
  "component---src-templates-blog-index-jsx": () => import("./../../../src/templates/blog-index.jsx" /* webpackChunkName: "component---src-templates-blog-index-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-contacts-jsx": () => import("./../../../src/templates/contacts.jsx" /* webpackChunkName: "component---src-templates-contacts-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-message-jsx": () => import("./../../../src/templates/message.jsx" /* webpackChunkName: "component---src-templates-message-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

